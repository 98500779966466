// import Swiper JS
import Swiper, { Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination]);

// How it works slider

const howCarousel = new Swiper('.how-carousel', {
  init: false,
  pagination: {
    el: '.how-pagination',
    clickable: true,
  },
});

if (document.querySelector('.how-carousel') !== null){
  if (window.matchMedia("(max-width: 80em)").matches) {
    howCarousel.init();
  }else{
    howCarousel.destroy( true, false )
  }
}

// Reviews slider
if (document.querySelector('.reviews-carousel') !== null){
  const reviews = new Swiper('.reviews-carousel', {
    pagination: {
      el: '.reviews-pagination',
      nextEl: '.reviews-button-next',
      prevEl: '.reviews-button-prev',
      clickable: true,
    },
  });
}

// Customer Stories slider
if (document.querySelector('.customerStories-carousel') !== null){

  const customerStories = new Swiper('.customerStories-carousel', {
    pagination: {
      el: '.customerStories-pagination',
      clickable: true,
    },
  });
}

// Article  slider
if (document.querySelector('.article-carousel') !== null){

  const article = new Swiper('.article-carousel', {
    autoHeight: true,
    pagination: {
      el: '.article-pagination',
      clickable: true,
    },
  });
}

// Ekomi  slider
if (document.querySelector('.ekomi-carousel') !== null){

  const ekomi = new Swiper('.ekomi-carousel', {
    breakpoints: {
      640: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 24,
      }
    },
    pagination: {
      el: '.ekomi-pagination',
      clickable: true,
    },
  });
}


