require('js-cookie');

/* Load CSS */
var loadDeferredStyles = function() {
  var addStylesNode = document.getElementById("deferred-styles");
  var replacement = document.createElement("div");
  replacement.innerHTML = addStylesNode.textContent;
  document.body.appendChild(replacement);
  addStylesNode.parentElement.removeChild(addStylesNode);
};
var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
if (raf) raf(function() { window.setTimeout(loadDeferredStyles, 0); });
else window.addEventListener('load', loadDeferredStyles);


/* extendTextBlock Toggle */
var elements = document.getElementsByClassName("extendTextBlock");

var toggle = function() {
  const toggleId = this.getAttribute('data-toggle');
  const gradient = document.querySelector('#' + toggleId + ' .textBlock__gradient')
  const expanded = document.querySelector('#' + toggleId + ' .expanded')

  this.style.display = 'none';
  gradient.style.display = 'none';
  expanded.style.display = 'block';
}

Array.from(elements).forEach(function(element) {
  element.addEventListener('click', toggle);
});


/* Load Images */
if ('loading' in HTMLImageElement.prototype) {
  const images = document.querySelectorAll('img[loading="lazy"]');
  images.forEach(img => {
    img.src = img.dataset.src;
  });
} else {
  const script = document.createElement('script');
  script.src = '/assets/js/lazysizes.min.js';
  document.body.appendChild(script);
}

/* Load iFrames */
if ('loading' in HTMLIFrameElement.prototype) {
  const iframes = document.querySelectorAll('iframe[loading="lazy"]');
  iframes.forEach(iframe => {
    iframe.src = iframe.dataset.src;
  });
} else {
  const script = document.createElement('script');
  script.src = '/assets/js/lazysizes.min.js';
  document.body.appendChild(script);
}

/* Floating CTA */
if (window.matchMedia("(max-width: 30em)").matches) {
  let scrollpos = window.scrollY
  let displayOffset = 540
  const cta = document.querySelector(".floatingCta")

  window.addEventListener('scroll', function() {
    scrollpos = window.scrollY;

    if (scrollpos >= displayOffset) {
      cta.classList.add("active")
    }else {
      cta.classList.remove("active")
    }
  })
}

const header = document.getElementById('header')
const navItems = document.getElementsByClassName('js-navbar__toggle')
for (var i = 0; i < navItems.length; i++){
  navItems[i].addEventListener('click', fixedToggle);
}

function fixedToggle(e){
  if (header.classList.contains('fixed')) {
    header.classList.remove('fixed');
  } else {
    header.classList.add('fixed');
  }
}

/* Track phone number  */
document.addEventListener("DOMContentLoaded", function () {
  const elements = document.querySelectorAll("a[href^='tel:']"),
    l = elements.length;
  for (let i = 0; i < l; ++i) {
    elements[i].addEventListener("click", function (e) {
      const phoneNumber = this.getAttribute('href').replace(/tel:/g, '');
      dataLayer.push({'event': 'trackEvent', 'eventCategory': 'Phone', 'eventAction': 'Click', 'eventLabel': phoneNumber});
    });
  }
}, false);


/* Track WhatsApp  */
document.querySelectorAll('.js-whatsapp').forEach(item => {
  item.addEventListener('click', event => {
    dataLayer.push({'event': 'trackEvent', 'eventCategory': 'WhatsApp', 'eventAction': 'Click'});
  })
})

/* Store external tracking in cookie */
let utmQuery = decodeURIComponent(window.location.search.substring(1)),
  utmVariables = utmQuery.split('&'),
  ParameterName,
  ParameterCount

const getTrackingValue = (inputParameter) => {
  for (ParameterCount = 0; ParameterCount < utmVariables.length; ParameterCount++) {
    ParameterName = utmVariables[ParameterCount].split('=');
    if (ParameterName[0] === inputParameter) {
      return ParameterName[1] === null ? null : ParameterName[1];
    }
  }
}

const valueExists = (value) => {
  return (value != null && value != '' && value != undefined)
}

const utmParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'gclid',
  'fbclid'
];

utmParams.forEach(param => {
  var pValue = getTrackingValue(param);

  if (valueExists(pValue)) {
    Cookies.set(param, pValue, {
      expires: 90
    });
  }
  let cValue = Cookies.get(param);
  let input = document.getElementById(param);
  if (input && valueExists(cValue)) {
    input.value = cValue;
  }
});

if (document.getElementById('smashleads-loader')){
  const smashleadsLoader = document.getElementById('smashleads-loader')
  const smashleadsId = smashleadsLoader.getAttribute('data-id')

  const utm_source = (Cookies.get('utm_source') ? Cookies.get('utm_source') : '')
  const utm_medium = (Cookies.get('utm_medium') ? Cookies.get('utm_medium') : '')
  const utm_campaign = (Cookies.get('utm_campaign') ? Cookies.get('utm_campaign') : '')
  const utm_content = (Cookies.get('utm_content') ? Cookies.get('utm_content') : '')
  const utm_term = (Cookies.get('utm_term') ? Cookies.get('utm_term') : '')
  const gclid = (Cookies.get('gclid') ? Cookies.get('gclid') : '')
  const fbclid = (Cookies.get('fbclid') ? Cookies.get('fbclid') : '')

  const utmParams = "&utm_source=" + utm_source + "&utm_medium=" + utm_medium + "&utm_campaign=" + utm_campaign + "&utm_content=" + utm_content + "&utm_term=" + utm_term + "&gclid=" + gclid + "&fbclid=" + fbclid
  smashleadsLoader.innerHTML = '<iframe title="Fragebogen" src="https://d1dpc5awi07bh0.cloudfront.net/?quiz=' + smashleadsId + '&tracking=true' + utmParams + '" frameborder=0 style="width: 1px; min-width: 100%; min-height: 540px; max-height: 100%; border: 0"></iframe>'
}

if(Cookies.get('gclid') && window.matchMedia("(max-width: 30em)").matches){
  document.querySelectorAll('.js-test-whatsapp').forEach(function(el) {
    el.style.display = 'none';
  });
  document.querySelectorAll('.js-test-phone').forEach(function(el) {
    el.classList.add('db');
  });
  console.log("Ga Phone");
}
