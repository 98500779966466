import MicroModal from 'micromodal';

MicroModal.init({
  onShow: modal => loadScript(modal.id),
  // onClose: modal => console.info(`${modal.id} is hidden`),
  openTrigger: 'data-micromodal-trigger',
  closeTrigger: 'data-custom-close',
  disableScroll: true,
  disableFocus: true,
  awaitOpenAnimation: false,
  awaitCloseAnimation: false,
  debugMode: false
});

function loadScript(id) {
  if(id != 'modal-jobApply'){
    var js = document.createElement('script');
    js.src = '/assets/js/hbspt.min.js';
    document.body.appendChild(js);
  }

  dataLayer.push({
    event: "trackEvent",
    eventCategory: "Button",
    eventAction: "Click",
    eventLabel: "Request"
  })
}
